import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'
import { rotateRule } from 'utils/animations'
import Button from 'components/Button'
import ToolbarButton from 'components/ToolbarButton'
import DropDownFieldForm from 'components/DropDownFieldForm'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Subtitle from 'elements/Subtitle'
import LogoImage from 'elements/LogoImage'
import CrmFieldForm from './CrmFieldForm'
import CrmImportCrons from './CrmImportCrons'
import InfoLabel from 'elements/InfoLabel'
import { CRM_FIELDS } from 'containers/Integrations/constants'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  padding: ${theme.padding};

  .svg-inline--fa.fa-spinner-third {
    animation: ${rotateRule};
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  position: relative;
`

const ButtonContainer = styled.div`
  flex: 1 0 auto;
  align-items: center;
  justify-content: end;
  display: flex;
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

const syncWhenOptions = Immutable.List([
  {
    value: '',
    label: 'Off'
  },
  {
    value: 'created',
    label: 'Sync contacts when added to a sequence'
  },
  {
    value: 'replied',
    label: 'Sync contacts only when they reply back'
  }
])

class ConfigureCRM extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  render () {
    const {
      state,
      crm,
      refreshing,
      sequenceFields,
      onCRMUpdate,
      onCRMRefresh,
      onCRMDisconnect,
      onDeleteCron,
      onCloneSettings
    } = this.props

    const isManager = state.get('is_manager')

    const name = crm.get('name')
    const type = crm.get('type')
    // const sync = crm.get('sync')
    const when = crm.get('when') || ''
    const dedupe = crm.get('dedupe')
    const model = crm.get('model')
    const list = crm.get('list')
    const listType = crm.get('list_type')
    const stage = crm.get('stage')
    const supportedStages = crm.get('stages')
    const stageType = crm.get('stage_type')
    const supportedModels = crm.get('models')
    const supportedLists = crm.get('lists')
    const log = crm.get('log')
    const logSupported = crm.get('log_supported')
    const logTaskSupported = crm.get('log_task_supported')
    const dedupeSupported = crm.get('dedupe_supported')
    const fields = crm.get('fields')
    const importCrons = crm.get('import_crons')

    let modelOptions
    if (supportedModels) {
      modelOptions = supportedModels.map(m =>
        ({
          value: m,
          label: m
        }))
    }
    let listOptions
    if (supportedLists) {
      listOptions = supportedLists.map(l =>
        ({
          value: l.get('id'),
          label: l.get('title')
        }))
    }
    let stageOptions
    if (supportedStages) {
      stageOptions = supportedStages.map(l =>
        ({
          value: l.get('id'),
          label: l.get('title')
        }))
    }

    return (
      <Wrapper>
        <TitleContainer>
          <Subtitle>Connected to {name}</Subtitle>
          <LogoImage type={type} ml={0} mr={0} mt='auto' mb='auto' />
          <ButtonContainer>
            {isManager &&
              <ToolbarButton
                key='clone_settings'
                tooltip='Clone settings to teammates'
                icon={['fal', 'copy']}
                size={44}
                onClick={onCloneSettings}
                ml='auto'
              />}
            <Button
              label='Disconnect'
              handleClick={onCRMDisconnect}
            />
          </ButtonContainer>
        </TitleContainer>
        {type === 'greenhouse' && !crm.get('import_contacts_supported') &&
          <InfoLabel mt='2rem'>
            <h1>Upgrade to advanced Greenhouse integration</h1>
            You are currently on our standard Greenhouse integration. You can upgrade to our advanced Greenhouse integration. <a href='https://help.interseller.io/article/201-using-interseller-with-greenhouse#harvestapi' target='_new'>Learn more about the upgrade</a>
          </InfoLabel>}
        <FieldContainer>
          <DropDownFieldForm
            label='Sync'
            description={`Set when contacts are synced to ${name}`}
            options={syncWhenOptions}
            value={when}
            inputId='when'
            onSave={(value) => {
              if (value === '') {
                value = null
              }

              onCRMUpdate({ when: value })
            }}
          />
        </FieldContainer>
        {dedupeSupported &&
          <FieldContainer>
            <SwitchFieldForm
              label='Deduplication'
              description={`Warn when a contact already exists in ${name}`}
              inputId='dedupe'
              value={dedupe}
              onSave={(value) => {
                onCRMUpdate({ dedupe: value })
              }}
            />
          </FieldContainer>}
        {modelOptions && modelOptions.count() > 0 &&
          <FieldContainer>
            <DropDownFieldForm
              label='Sync as'
              description='Set how new contacts are synced'
              options={modelOptions}
              value={model}
              inputId='model'
              onSave={(value) => {
                onCRMUpdate({ model: value })
              }}
            />
          </FieldContainer>}
        {listOptions &&
          <FieldContainer>
            <DropDownFieldForm
              label={`${listType}`}
              description={`Set ${listType} to sync new contacts to`}
              clearable
              searchable
              options={listOptions}
              value={list}
              inputId='list'
              refreshing={refreshing && this.field === CRM_FIELDS.LISTS}
              onSave={(value) => {
                onCRMUpdate({ list: value })
              }}
              onRefresh={() => {
                this.field = CRM_FIELDS.LISTS
                onCRMRefresh({ include_import_options: true })
              }}
              refreshTooltip={`Refresh ${listType} from ${name}`}
            />
          </FieldContainer>}
        {stageOptions &&
          <FieldContainer>
            <DropDownFieldForm
              label={`${stageType}`}
              description={`Set the default ${stageType} that new contacts sync to`}
              clearable
              searchable
              options={stageOptions}
              value={stage}
              inputId='stage'
              refreshing={refreshing && this.field === CRM_FIELDS.STAGES}
              onSave={(value) => {
                onCRMUpdate({ stage: value })
              }}
              onRefresh={() => {
                this.field = CRM_FIELDS.STAGES
                onCRMRefresh({ include_import_options: true })
              }}
              refreshTooltip={`Refresh ${stageType} from ${name}`}
            />
          </FieldContainer>}
        {(logSupported || logTaskSupported) &&
          <FieldContainer>
            <SwitchFieldForm
              label='Activity Tracking'
              description={
              `Automatically log ${logSupported ? 'emails sent/received ' : ''}
              ${(logSupported && logTaskSupported) ? 'and ' : ''}
              ${logTaskSupported ? 'tasks completed ' : ''}
              from contacts`
            }
              inputId='log'
              value={log}
              onSave={(value) => {
                onCRMUpdate({ log: value })
              }}
            />
          </FieldContainer>}
        {fields &&
          <FieldContainer>
            <CrmFieldForm
              label='Field Mapping'
              description={`Map data in Interseller to fields in ${name}`}
              crm={crm}
              sequenceFields={sequenceFields}
              onSave={(value) => {
                onCRMUpdate({ mapping: value })
              }}
            />
          </FieldContainer>}
        {importCrons && importCrons.size > 0 &&
          <FieldContainer>
            <CrmImportCrons
              label='Scheduled Imports'
              description={`Scheduled contact imports from ${name}`}
              importCrons={importCrons}
              onDeleteCron={onDeleteCron}
            />
          </FieldContainer>}
      </Wrapper>
    )
  }
}

ConfigureCRM.propTypes = {
  state: PropTypes.object,
  crm: PropTypes.object,
  refreshing: PropTypes.bool,
  sequenceFields: PropTypes.object,
  onCRMUpdate: PropTypes.func,
  onCRMRefresh: PropTypes.func,
  onCRMDisconnect: PropTypes.func,
  onDeleteCron: PropTypes.func,
  onCloneSettings: PropTypes.func
}

export default ConfigureCRM
